html {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #131313;
  line-height: 1.45;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  box-shadow: 0 2px 5px grey;
  position: relative;

  @media (min-width: 1200px) {
    padding: 20px 50px;
  }
}

.logo {
  width: 50px;

  @media (min-width: 640px) {
    width: 80px;
  }
}

.title {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  @media (min-width: 640px) {
    font-size: 25px;
  }
}

.contact {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 2;

  @media (min-width: 640px) {
    font-size: 14px;
  }
}

.phone-number, .email {
  text-decoration: none;
  
  @media (min-width: 640px) {
    color: #131313;
  }
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 20px;
  margin: 50px 15px;
  text-align: center;

  .quoted {
    font-size: 16px;
    margin-top: 10px;
  }
}

.email {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  text-align: right;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.iframe-container {
  width: 70%;

  @media (min-width: 640px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 40%;
  }
}

iframe {
  aspect-ratio: 16/9;
  width: 100%;
}

.description {
  margin: 60px 25px;
  text-align: center;
}

.shop-button {
  text-decoration: none;
  background-color: #131313;
  color: white;
  padding: 5px 15px;
  border-radius: 50px;
}

.shop-button:hover {
  background-color: #343434;
}